import React, { useState } from "react";
import SellerDropDown from "../Shared/SellerDropDown/SellerDropDown";
import DateRange from "../Shared/DateRange/DateRange";
import DataTablePaginated from "../Shared/Table/TablePaginated";
import {
	getFormattedStartTime,
	getFormattedEndTime,
	getDateFromDayNumber
} from "../../utils/date.js";
import { CheckIfSellerSelected } from "../../utils/seller";
import {
	Box,
	Grid,
	Typography,
	Paper
} from "@mui/material";
import * as tableConstants from "../Shared/Config/TableConfig";
import { getSellerDetails } from "../../utils/getSellerDetails";
import { PageHeading } from "../Shared/PageHeading/PageHeading";

export default function FinanceValueAddedServices() {

	const pageRowOptions = tableConstants.PAGE_ROW_OPTIONS;
	const [state, setState] = useState({
		selectedSeller: "",
		selectedSellerID: 0,
		startDate: getFormattedStartTime(getDateFromDayNumber(-31)),
		endDate: getFormattedEndTime(getDateFromDayNumber(0)),
		currentPageOrderAdjustments: 1,
		rowsPerPageOrderAdjustments: pageRowOptions[3],
		csvFileName: "",
	});

	function onSellerSelect(seller) {
		setState(previousState => {
			return {
				...previousState,
				selectedSeller: seller,
				selectedSellerID: getSellerDetails(seller, "id"),
				currentPageOrderAdjustments: 1,
				csvFileName: `${getSellerDetails(seller, "code")}_Finance_Order_Adjustments_csv`,
			};
		});
	}

	function onStartDateChange(start) {
		setState(previousState => {
			return {
				...previousState,
				startDate: start,
				currentPageOrderAdjustments: 1,
			};
		});
	}

	function onEndDateChange(end) {
		setState(previousState => {
			return {
				...previousState,
				endDate: end,
				currentPageOrderAdjustments: 1,
			};
		});
	}

	const onChangePageRowOrderAdjustments = (pageRows) => {
		setState(previousState => {
			return {
				...previousState,
				rowsPerPageOrderAdjustments: pageRows,
				currentPageOrderAdjustments: 1,
			};
		});
	};

	const params = new URLSearchParams({
		sellers: state.selectedSellerID,
		start_date: getFormattedStartTime(state.startDate),
		end_date: getFormattedEndTime(state.endDate),
	});

	const orderAdjustmentsURL = "/v1/invoicing/order-adjustments?";

	const orderAdjustmentsHeader = [
		"seller",
		"order_number",
		"date",
		"comments",
		"fulfillment_price",
		"delivery_price"
	];

	const OrderAdjustmentsTable = () => (
		<Paper sx={{ p: 2, width: "100%" }} elevation={2}>
			<Typography variant="h6" sx={{ marginBottom: 1 }}>
				Order Adjustments
			</Typography>
			<DataTablePaginated
				headers={orderAdjustmentsHeader}
				dateHeaders={["date"]}
				seller={state.selectedSellerID}
				url={orderAdjustmentsURL}
				params={params}
				currentPage={state.currentPageOrderAdjustments}
				rowsPerPage={state.rowsPerPageOrderAdjustments}
				onChangePageRow={onChangePageRowOrderAdjustments}
				csvFilename={state.csvFileName}
			/>
		</Paper>
	);

	const HeadingContent = () => {
		return (
			<>
				<Grid item xs />
				<Grid item mt={1}>
					<Grid item xs="auto">
						<SellerDropDown
							onChangeSelect={onSellerSelect}
							selected={state.selectedSeller}
						/>
						<DateRange
							startDate={state.startDate}
							endDate={state.endDate}
							onStartDateChange={onStartDateChange}
							onEndDateChange={onEndDateChange}
						/>
					</Grid>
				</Grid>
			</>
		);
	};

	return (
		<Box id="finance-vas">
			<PageHeading pageTitle={"Finance - Value Added Services"}>
				<HeadingContent/>
			</PageHeading>
			{!state.selectedSellerID ?
				<CheckIfSellerSelected/>
				:
				<OrderAdjustmentsTable/>
			}
		</Box>
	);
}